import axios from "axios";
import React,{useState,useEffect} from "react";



import tornpaper from "./images/torn_paper.png" 
const Record = (props) => (
    <div style={{
        //display: "flex",
               // justifyContent: "centre",
               // alignItems: "centre",
                //background: "White",
                flexFlow: "column",
               // margin: "50px"  ,
                wordWrap: "break-word" ,
                margin: "1vw"
               // marginRight: "70px",
               // marginLeft: "100px",
                //marginTop: "50px"
                        
    }}
    ><p >{props.record.name}</p>
    <p>- {props.record.id}</p>
    </div>
    
      
      
      
   );

const Vachanagalu = () => {
    const [records, setRecords] = useState([]);

 // This method fetches the records from the database.
 useEffect(() => {
   async function getRecords() {
     const response = await axios.get('/record/random');
     console.log(response)

     if (response.status !== 200 ) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const records = response.data;
     setRecords(records);
   }

   getRecords();

   return;
 }, [records.length]);

 function recordList() {
    return records.map((record) => {
      return (
        <Record
          record={record}
          key={record._id}
        />
      );
    });
  }

    return (
        <div
            style={{
                 display: "flex",
                // justifyContent: "centre",
                // alignItems: "centre",
                // //background: "White",
                 flexDirection: "column",
                // height: "300px",
                 //width: "250px",
                
                
            }}
        >

            <span> {recordList()}
</span>
        </div>
    );
};
 
export default Vachanagalu;