import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { toast } from "react-toastify";
import "../components/css/addVachana.css"
import Navbar from "./Navbar/Navbar";


export default function AddRecord() {
    const [form, setForm] = useState({
        name: "",
        id: "",
        records: [],
      });
 const params = useParams();
 const navigate = useNavigate();

 

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 async function onSubmit(e) {
   e.preventDefault();
   const editedPerson = {
     id: form.id,
     name: form.name
   };
   console.log(editedPerson)
   const data = JSON.stringify(editedPerson);
   // This will send a post request to update the data in the database.
   const response = await axios.post(`/record/`, 
     
     { data},{
     headers: {
       'Content-Type': 'application/json'
     },withCredentials: true }
   );
    if (response.statusText === "OK"){
     toast("Vachana Added Succesfully")
    }
    else{
      toast.error("Error While Adding Record")
    }
   navigate("/blogs");
 }

 // This following section will display the form that takes input from the user to update the data.
 return (
  <div>
    <Navbar/>
  
   <div style={{minHeight: "100vh"}}>
     <h3>Add Vachana</h3>
     
     <form onSubmit={onSubmit}>
     <table style={{textAlign: "left"}}>
        <tr>
            <td><label htmlFor="id">Author </label></td>
            <td>
            <div className="form-group">       
        <input
          type="text"
          className="form-control"
          id="id"
          value={form.id}
          onChange={(e) => updateForm({ id: e.target.value })}
        />
      </div>
            </td>
            
        </tr>
        <tr>
            <td><label htmlFor="name">Vachana </label></td>
            <td>
            <div className="form-group">
         
         <textarea
           type="textarea"
           className="form-control"
           id="name"
           value={form.name}
           onChange={(e) => updateForm({ name: e.target.value })}
         />
       </div>
            </td>
        </tr>
        <tr>
            <td></td>
            <td><div className="form-group">
         <input
           type="submit"
           value="Add Vachana"
           className="btn btn-primary"
         />
       </div></td>
        </tr>
     </table>
     
       
       
       
     </form>
   </div>
   </div>
 );
}