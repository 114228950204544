import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

export default function Admin() {
    const [form, setForm] = useState({
        password: "",
        id: "",
        records: [],
      });
 const params = useParams();
 const navigate = useNavigate();

 

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 async function onSubmit(e) {
    e.preventDefault();
    const editedPerson = {
    password: form.password,
    userId: form.id
   };
    console.log(editedPerson)
    const userId = editedPerson.userId.toString();
    let password = editedPerson.password.toString();
    let response1 = await fetch(`http://localhost:5050/record/logins`,
    {
        method: "POST",
        body: JSON.stringify({user_id: userId,password: password}),
        headers: {
          'Content-Type': 'application/json'
        },
    });
    const response = await response1.json();
     
     if (response.status == false) {
        
       window.alert(`User ${userId} not found`);
     }
     else
     {
        password = response.user_id;
        //window.alert("Correct Login");
        navigate("/admin");

     }
   

   
 }

 // This following section will display the form that takes input from the user to update the data.
 return (
   <div>
     <h3>Admin Sign In</h3>
     
     <form onSubmit={onSubmit}>
     <table style={{textAlign: "left"}}>
        <tr>
            <td><label htmlFor="id">User Name </label></td>
            <td>
            <div className="form-group">       
        <input
          type="text"
          className="form-control"
          id="id"
          value={form.id}
          onChange={(e) => updateForm({ id: e.target.value })}
        />
      </div>
            </td>
            
        </tr>
        <tr>
            <td><label htmlFor="password">Password </label></td>
            <td>
            <div className="form-group">       
        <input
          type="password"
          className="form-control"
          id="password"
          value={form.password}
          onChange={(e) => updateForm({ password: e.target.value })}
        />
      </div>
            </td>
            
        </tr>
       
        <tr>
            <td></td>
            <td><div className="form-group">
         <input
           type="submit"
           value="Login"
           className="btn btn-primary"
         />
       </div></td>
        </tr>
     </table>
     
       
       
       
     </form>
   </div>
 );
}