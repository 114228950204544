import logo from './logo.svg';
import LoginForm from './components/form';
import './App.css';
import TopNavBar from './components/navigationBar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Footer from "./components/footer/footer"

function App() {
  return (
    
   
    <body style={{height:"auto"}}>
      
    <div className="App">
    <ToastContainer/>
      <TopNavBar></TopNavBar>
      
    </div>
    <Footer/>
    </body>
    
  );
}

export default App;
