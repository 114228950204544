import React from 'react'
import "../css/footer.css"

import { FaFacebook,FaInstagramSquare,FaYoutube } from "react-icons/fa";


const footer = () => {
  return (
    <div class="footer">
        <div class="socialIcons">
        <FaFacebook />
        <FaInstagramSquare />
        <FaYoutube/>

        </div>
        <div class="copyright">
            <p class="copyright">Basava Balaga  © 2024</p>
        </div>
        
    </div>
  )
}

export default footer;