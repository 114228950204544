import React  from "react";
import './navigation.css'
import { NavLink } from "react-router-dom";
import Vachanagalu from "./vachanagalu";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Navbar from "./Navbar/Navbar"
import Home from "./home";
import Events from "./events";
import About from "./Navbar/about";
import Blogs from "./blogs";
import Teams from "./team";
import SignUp from "./signup";
import Edit from "./edit";
import AddRecord from "./addRecord";
import Login from "./login";
import Admin from "./admin";
import UserLogin from "./loginpage";
import HomePage from "./homepage";
import Contact from "./Navbar/contact";
import { ContextProvider } from "./context";
import Vachanas from "./vachanas";
import AdminPage from "./admin/admin";

export default function TopNavBar(){
    
    
    // return (
    //     <html>
    //         <head>
    //         <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
    //         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    //         </head>
    //     <div class="topnav" id="myTopnav">
    //     <a href="#home" class="active">Home</a>
    //     <a href=<Vachanagalu></Vachanagalu> >Vachanagalu</a>
    //     <a href="#contact">Contact</a>
    //     <a href="#about">About</a>
    //     <a href="javascript:void(0);" class="icon" onClick={myFunction} >
    //         <i class="fa fa-bars"></i>
    //     </a>
    //     </div>
    //     </html>
    // );

    return (
        
        <Router>
            {/* <Navbar /> */}
            <ContextProvider>
            <Routes>
            
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route
                    path="/events"
                    element={<Events />}
                />
                <Route path="/addRecord" element={<AddRecord/>}></Route>
                <Route path="/team" element={<Teams />} ></Route>
                <Route path="/edit/:id" element={<Edit />} ></Route>
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/login" element={<Login/>}></Route>
                <Route path="/admin" element={<Admin/>}></Route>
                <Route path="/userlogin" element={<UserLogin/>}></Route>
                <Route path="/homepage" element={<HomePage/>}></Route>
                <Route path="/contact" element={<Contact/>}></Route>
                <Route path="/vachanas" element={<Vachanas/>}></Route>
                <Route path="/adminPage" element={<AdminPage/>}></Route>
                <Route
                    path="/signup"
                    element={<SignUp />}
                />
                
            </Routes>
            </ContextProvider>
        </Router>
        
    );
}