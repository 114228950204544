import React from "react";
 
const Admin = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "auto",
            }}
        >
            <h1>
                Basava Balaga
            </h1>
        </div>
    );
};
 
export default Admin;