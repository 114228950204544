import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

export default function Edit() {
 const [form, setForm] = useState({
   name: "",
   id: "",
   records: [],
 });
 const params = useParams();
 const navigate = useNavigate();

 useEffect(() => {
   async function fetchData() {
     const id = params.id.toString();
     const response = await axios.get(`/record/${params.id.toString()}`);
    console.log(response)
     if (response.status !== 200) {
       const message = `An error has occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const record = await response.data;
     if (!record) {
       window.alert(`Record with id ${id} not found`);
       navigate("/blogs");
       return;
     }

     setForm(record);
   }

   fetchData();

   return;
 }, [params.id, navigate]);

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 async function onSubmit(e) {
   e.preventDefault();
   const editedPerson = {
     id: form.id,
     name: form.name
   };
   console.log("edited",editedPerson)
   // This will send a post request to update the data in the database.
   await axios.patch(`/record/${params.id}`, {
     
      editedPerson},{ withCredentials: true,
     headers: {
       'Content-Type': 'application/json'
     },
   });

   navigate("/blogs");
 }

 // This following section will display the form that takes input from the user to update the data.
 return (
   <div>
     <h3>Update Record</h3>
     <form onSubmit={onSubmit}>
       <div className="form-group">
         <label htmlFor="id">ID: </label>
         <input
           type="text"
           className="form-control"
           id="id"
           value={form.id}
           onChange={(e) => updateForm({ id: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="name">Name: </label>
         <input
           type="text"
           className="form-control"
           id="name"
           value={form.name}
           onChange={(e) => updateForm({ name: e.target.value })}
         />
       </div>
       
      

       <div className="form-group">
         <input
           type="submit"
           value="Update Record"
           className="btn btn-primary"
         />
       </div>
     </form>
   </div>
 );
}