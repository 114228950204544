import React from 'react'

const headerTitle = () => {
  return (
    <div><span style={{
         
    background: "-webkit-linear-gradient(  #0808ef , #ff7500)",
     
    WebkitBackgroundClip: 'text',
    //"-webkit-background-clip": "text",
    fontSize: "4vw",
    WebkitTextFillColor: 'transparent',
    //</div>"-webkit-text-fill-color": "transparent",
    textAlign: 'center',
    WebkitTextStroke: 'thin',
    display: 'block'
    

    }
    }>ಬಸವ ಬಳಗ</span></div>
  )
}

export default headerTitle