//import React from "react";
 
// const Blogs = () => {
//     return (
//         <div
//             style={{
//                 display: "flex",
//                 justifyContent: "centre",
//                 alignItems: "centre",
//                 height: "100vh",
//             }}
//         >
//             <h1>
//                 GeeksforGeeks is a Computer Science portal
//                 for geeks.
//             </h1>
//         </div>
//     );
// };
 
//export default Blogs;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AddRecord from "./addRecord";
import Navbar from "./Navbar/Navbar";
import "../components/css/addVachana.css"
const Record = (props) => (
 <tr>
   <td>{props.record.id}</td>
   <td>{props.record.name}</td>
   
   <td>
     <Link className="btn btn-link" to={`/edit/${props.record._id}`}>Edit</Link> |
     <button className="btn btn-link"
       onClick={() => {
         props.deleteRecord(props.record._id);
       }}
     >
       Delete
     </button>
   </td>
 </tr>
);

export default function Blogs() {
 const [records, setRecords] = useState([]);

 // This method fetches the records from the database.
 useEffect(() => {
   async function getRecords() {
     const response = await axios.get(`/record/`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const records = await response.data;
     setRecords(records);
   }

   getRecords();

   return;
 }, [records.length]);

 // This method will delete a record
 async function deleteRecord(id) {
   await axios.delete(`/record/${id}`, {
     method: "DELETE"
   });

   const newRecords = records.filter((el) => el._id !== id);
   setRecords(newRecords);
 }

 // This method will map out the records on the table
 function recordList() {
   return records.map((record) => {
     return (
       <Record
         record={record}
         deleteRecord={() => deleteRecord(record._id)}
         key={record._id}
       />
     );
   });
 }

 // This following section will display the table with the records of individuals.
 return (
   <div>
    <Navbar/>
    <div style={{minHeight:"100vh"}}>
    <h3> <a class="addRecordLink" href="/addRecord"> Add Vachana</a></h3>
   
        
    
     <h3>Record List</h3>
     <table className="table table-striped" style={{ marginTop: 20 }}>
       <thead>
         <tr>
           <th>ID</th>
           <th>Name</th>
           
         </tr>
       </thead>
       <tbody>{recordList()}</tbody>
     </table>
     </div>
   </div>
 );
}