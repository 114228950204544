import React, { useState, useContext } from "react";
import HeaderTitle from "./header";
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import UserName from "../homepage"
import { Context } from "../context";

 

export const Navbar = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [login,setLoginopen] = useState(false);
    const [cookies, removeCookie] = useCookies(['sampletoken']);
    const navigate = useNavigate();
    console.log("navbarcontext",useContext(Context));
    const [username,setUsername] = useContext(Context);
    console.log("nav",useContext(Context), username );
    //const user = useContext(Context)[0];
    
    
    console.log("user p", username , "uu",username[0]);
    const Logout = () => {
      removeCookie("sampletoken");
      setUsername("");
      
      navigate("/userlogin");
    };
    let section ="";
    let admin_page="";
      if (username == "admin") {
         section= <NavLink to="/blogs">Vachan Sahitya</NavLink>;
         admin_page= <NavLink to="/adminPage">Admin Home</NavLink>;
      }
      else{
        section= <NavLink to="/vachanas">Vachan Sahitya</NavLink>;
      }
    
    let loginSection="";
    
    if (username == undefined || username=="")
    {
      loginSection = <li><button className="loginButton" onClick={Logout} style={{ 
        border: "0",
        color: "black",
        background: "transparent",
        
        textTransform: "capitalize",
        fontFamily:"sans-serif",fontWeight:"bold",

}}>Login</button></li>
    }
    else if(username != undefined && username[0] == ''){
      loginSection = <li><button className="loginButton" onClick={Logout} style={{ 
        border: "0",
        color: "black",
        background: "transparent",
        
        textTransform: "capitalize",
        fontFamily:"sans-serif",fontWeight:"bold",

}}>Login</button></li>
    }
    else
    {
      loginSection=<li className="menu1" onMouseEnter={() => setLoginopen(!login)} style={{}}>
      <FaUserCircle /></li>
      
    }
    
    return (
      <nav>
        <div style={{width: "35vw"}}>
        <Link to="/" className="title">
          <FaHome/> Home
        </Link></div>
        <div class="website_title">
        <HeaderTitle/>
        </div>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span className={menuOpen ? "first_span_open" : ""}></span>
          <span className={menuOpen ? "sec_span_open" : ""}></span>
          <span className={menuOpen ? "third_span_open" : ""}></span>
        </div>
        <div class="menu-ul">
        <ul className={menuOpen ? "open" : "close"} style={{}}>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          
          <li>
            {section}
              {/* <NavLink to="/blogs">Vachan Sahitya</NavLink> */}
            
            
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
          {loginSection}
          {/* <li className="menu1" onClick={() => setLoginopen(!login)} style={{fontSize:"xx-large"}}>
          <FaUserCircle /></li> */}
          <div class={login ? "loginOpendiv" : "loginClosediv"} >
          <ul className={login ? "open1" : "close1"}>
            <li>{username}</li>
            <li>{admin_page}</li>
            
          <li><button onClick={Logout}  className="logoutButton" style={{ 
            border: "0",
            color: "black",
            background: "transparent",
            margin: 0,
            padding: 0,
            textTransform: "capitalize",
            fontFamily:"sans-serif",fontWeight:"bold"

    }}>Logout</button></li>
          
        </ul>
        </div>
          
         
        </ul>
        </div>
        
        
        

        
      </nav>
    );
  };

  export default Navbar;