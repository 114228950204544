//import React from "react";
 
// const Blogs = () => {
//     return (
//         <div
//             style={{
//                 display: "flex",
//                 justifyContent: "centre",
//                 alignItems: "centre",
//                 height: "100vh",
//             }}
//         >
//             <h1>
//                 GeeksforGeeks is a Computer Science portal
//                 for geeks.
//             </h1>
//         </div>
//     );
// };
 
//export default Blogs;

import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AddRecord from "./addRecord";
import Navbar from "./Navbar/Navbar";

import Footer from "../components/footer/footer"
import "./css/vachanas.css";
import axios from "axios";

const Record = (props) => (
  
 <tr>
   <td>{props.record.id}</td>
   <td>{props.record.name}</td>
 </tr>
);



export default function Vachanas() {
 const [records, setRecords] = useState([]);
 const [selectSeacrh, setSelectSeacrh] = useState("vachana");
 const [myOptions, setMyOptions] = useState([])
 const [filterText, setFilterText] = useState('');

 console.log(selectSeacrh);
 // This method fetches the records from the database.
 useEffect(() => {
   async function getRecords() {
     const response = await axios.get('/record/');

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const records = await response.data;
     setRecords(records);
   }

   getRecords();

   return;
 }, [records.length]);

 // This method will delete a record
 async function deleteRecord(id) {
   await axios.delete(`/record/${id}`
   );

   const newRecords = records.filter((el) => el._id !== id);
   setRecords(newRecords);
 }

 // This method will map out the records on the table
 function recordList() {
   return records.map((record) => {
    if (selectSeacrh==="vachana"){
    if (
      record.name.toLowerCase().indexOf(
        filterText.toLowerCase()
      ) === -1
    ) {
      return;
    }}
    else if (selectSeacrh==="author"){
      if (
        record.id.toLowerCase().indexOf(
          filterText.toLowerCase()
        ) === -1)
        {
          return;
        }
    }
    
     return (
       <Record
         record={record}
         key={record._id}
       />
     );
   });
 }

 // This following section will display the table with the records of individuals.
 return (
   <div>
    <Navbar/>
    <div style={{minHeight:"100vh"}}>
    
        
    
     <h1 style={{textAlign:"center",
    background: "-webkit-linear-gradient( red , orange)",
    "-webkit-background-clip": "text",
    fontSize: "3vw",
   
    "-webkit-text-fill-color": "transparent",
    textAlign: 'center'
    }}>ವಚನಗಳು</h1>
    <table class="searchbox"><tr>
      <td class="searchboxcol"> <label>Search Vachana : </label></td>
      <td class="searchboxcol">
        <select value={selectSeacrh} onChange={e => setSelectSeacrh(e.target.value)}> 
          <option value="author">By Author</option>
          <option value="vachana">By Vachana</option>
        </select></td>
      <td class="searchboxcol">
      <form>
      <input 
        type="text" 
        value={filterText} placeholder="Search..." 
        onChange={(e) => setFilterText(e.target.value)} />
      </form>  


      </td>
      </tr></table>
   
    
     <table className="table table-striped" style={{ marginTop: 20 }}>
       <thead>
         <tr>
           <th>Author</th>
           <th>Vachana</th>
           
         </tr>
       </thead>
       <tbody>{recordList()}</tbody>
     </table>
     </div>
    
     
     
   </div>

   
 );
}