import React, { useState } from "react";
 
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [username, setUsername] = useState(['']);
 
    return (
        <Context.Provider value={[ username, setUsername ]}>
            {children}
        </Context.Provider>
    );
};