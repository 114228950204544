import React, { useContext,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {  toast } from "react-toastify";
import "../signon.css"
import TopNavBar from "./navigationBar";
import HeaderTitle from "./Navbar/header";
import { useCookies } from "react-cookie";
import { Context } from "./context";
import Navbar from "./Navbar/Navbar";

const UserLogin = () => {
  const navigate = useNavigate();
  const [username,setUsername] = useContext(Context);
  const [cookies, setCookie,removeCookie] = useCookies(['sampletoken']);
  
  
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const { email, password } = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "/signon/login",
        {
          ...inputValue,
        },
        { withCredentials: true }
      );
      
      const { success, message, user, token } = data;

      
      if (success) {
      console.log("login",data);
      setCookie("sampletoken", data.token);
     
      setUsername(data.user);
      console.log("loginpage",cookies,"user",username);

        handleSuccess(message);
        setTimeout(() => {
          navigate("/homepage", {state: data});
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }
    setInputValue({
      ...inputValue,
      email: "",
      password: "",
    });
  };

  return (
    <>
    
    <div>
      <div><Navbar/></div>
      <div style={{minHeight:"100vh"}}>
           <div className="form_container">
              <h2>Login Account</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Enter your password"
            onChange={handleOnChange}
          />
        </div>
        <button type="submit">Submit</button>
        <span>
          Already have an account? <Link to={"/signup"}>Signup</Link>
        </span>
      </form>
      
    </div>
    </div>
    </div>
    </>
  );
};

export default UserLogin;