import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from "../footer/footer";

import SendMessages from './sendMessage'

export default function () {
  return (
    <div >
        <Navbar></Navbar>
        <div style={{minHeight:"40vw"}}><SendMessages></SendMessages></div>
        
    </div>
  )
}
