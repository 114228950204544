import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

//axios.defaults.baseURL = "http://192.168.1.7:5050"; //"http://localhost:5050"
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.baseURL = 'https://basavabalaga-api.onrender.com';//"http://localhost:5050"
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    
    

    <App />
    
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
