import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies, Cookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { FaUserCircle } from "react-icons/fa";
import "../signon.css"
import Navbar from "./Navbar/Navbar";
import logo from "./images/basava_bkg.jpg" 
import slider2 from "./images/akk3.png"
import Vachanagalu from "./vachanagalu";
import React from 'react';
import { Context } from "./context";
import SimpleImageSlider from "react-simple-image-slider";


const HomePage = () => {
  
  const navigate = useNavigate();
  const {state} = useLocation();
  console.log("state", state);
  const [cookies, removeCookie] = useCookies(['sampletoken']);
  //const [username, setUsername] = useState("");
  console.log("usecoo",cookies);
  const [username,setUsername] = useContext(Context);
  console.log("home",useContext(Context), username );
  const images = [
    { url: logo },
    { url: slider2 },
    
    
  ];
  const toastId = useRef(null);
  //const [toastShown, setToastShown] = useState(false);
  useEffect(() => {
   
  //   if (state)
  // {
  //   setUsername(state['user']);
  //   console.log("home2",username);
  // }
    const verifyCookie = async () => {
      
      
      if (!cookies.sampletoken) {
        navigate("/userlogin");
      }
      
      const { data } = await axios.post(
        "/signon/",
        {},
        { withCredentials: true
        
        }
        
      );
      const { status, user } = data;
      console.log("data" ,data);
      //setUsername(user);
      const notify="";
      //user=username;
      console.log("userna", username)
      if (user) {
        const word = username;
        const firstLetter = word.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = word.slice(1);
        const capitalizedWord = firstLetterCap + remainingLetters;
        if(! toast.isActive(toastId.current)) {
          const notify = toast(`Hello ${capitalizedWord}`, 
            {
              position: "top-left",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
          }
            );
        }
      return status
        ? notify
        : (removeCookie("sampletoken"), navigate("/userlogin"));
      }
      else{
        
        // if(! toast.isActive(toastId.current)) {
        //   toastId.current = toast.success(`Hello Guest`,{position: "top-left"},{toastId: "ido"},{autoClose: 2000, hideProgressBar: true},{limit:1});
        // }
        
        //toast(`Hello Guest`,{position: "top-left"},{toastId: "id",autoClose: 100});
        toast('Hello Guest', {
          position: "top-left",
          
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setUsername("Guest");
        console.log("Guest")
        // if (!toastShown) {

        //   if(! toast.isActive(toastId.current)) {
        //     toastId.current = toast.success(`Hello Guest`,{position: "top-left"},{toastId: "idoi"},{autoClose: 1000, hideProgressBar: true},{limit:1});
        //     setToastShown(true);
        //   }}
        // if (!toastShown) {

      
        //   toastId.current = toast.success(`Hello Guest`,{position: "top-left"},{toastId: "idoi"},{autoClose: 1000, hideProgressBar: true},{limit:1});
        //   setToastShown(true);
        // }
      }
      

    };
    console.log("print user",username,username[0]);
    console.log("ppppp", cookies)
    if (  username[0] === "" )
    {
      toast('Hello Guest', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    
    }
    else if( username === "")
    {
      toast('Hello Guest', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    else{
      verifyCookie();
      //setUsername("Guest");
    }
    
  }, [cookies, navigate, removeCookie]);
  
  const Logout = () => {
    removeCookie("sampletoken");
    setUsername("");
    navigate("/userlogin");
  };
  

  return (
    <>
      
      <Navbar user={username} logout={Logout}></Navbar>
      
      <div class="mainbody">
        <div class="body">
            <div class="vachana"><Vachanagalu/></div>
            <div class= "image_logo">
              <div className="logo">
            <SimpleImageSlider
              width={896}
              height={504}
              images={images}
              showBullets={true}
              showNavs={true}
              autoPlay={true}
            /></div>
                {/* <img class="logo" src={logo} var="logo"></img> */}
                
            </div>
            <div class="vachana"><Vachanagalu/></div>
            
        </div>
        <div class="playvideo"> <text>Gallery</text>
        <iframe class="iframevideo" src="https://www.youtube.com/embed/z5FmRUJASbA" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameborder="0" allowFullScreen>
        </iframe>
        </div>
      
        </div>
        
      
    </>
    
  );

  }


export default HomePage;
