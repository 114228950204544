import React from 'react'
import Navbar from './Navbar'
import Footer from '../footer/footer'
import "../css/contact.css"

const contact = () => {
  return (
    <div>
            <Navbar/>
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                minHeight: "100vh",
                flexDirection: "column"
            }}
        >
            <div><h1>
                Contact us: 
            </h1></div>
            <div class="main">
                <div>
                <h3>Email: vinayak3700ssbj@gmail.com</h3>
                <h3>Phone: 8867441099</h3>
                </div>
            </div>
        </div>
        
        </div>
  )
}

export default contact