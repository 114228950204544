import React from "react";
import Navbar from "./Navbar";
import Footer from "../footer/footer";
import { height } from "@mui/system";
import "../css/about.css"

const About = () => {
    return (
        <div>
            <Navbar/>
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                minHeight: "100vh",
                flexDirection: "column"
            }}
        >
            <h1>
                About Basava Balaga
            </h1>
            <div class="main">
                <div>
                    <h3>Overview</h3>
                </div>
                <div>
                    <h3>Team</h3>
                    
                </div>
                <div>
                <h3>Achievements</h3>
                </div>
            </div>
            
            
            
        </div>
       
        </div>
    );
};
 
export default About;